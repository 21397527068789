import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Skus from "../components/skus"
import { formatCurrencyString } from 'use-shopping-cart'

import '../ui/product.css'
import AddBasket from "../components/add-basket"
import Seo from "../components/SEO"

export default function TemplateProduct({
  data // this prop will be injected by the GraphQL query below.
}) {
   
  const { stripePrice } = data 

  const post = {
    title: stripePrice.product.name + " effet moussant et relaxant",
    description: stripePrice.product.description,
    image: 'https://archimouss.ch/assets/images/logo/big.png'
  }

  return (
    <>
      <Seo title={post.title} description={post.description} image={post.image}/>
      <Layout displayIcons={true} displayBasket={true}>
        <div className='main'>
          <div className='main-content'>
            <div className='main-product'>
              <div className="product-content">
                <div className="product-info">
                  <h1>{stripePrice.product.name}</h1>
                  <p>{stripePrice.product.description}</p>
                  

                  <h2>Utilisation</h2>
                  <p>1. Faites couler un bain chaud.</p>
                  <p>2. Jetez-y une bombe de bain choisie selon votre humeur.</p>
                  <p>3. Contemplez l’explosion effervescente et respirez le somptueux parfum. Laissez vos soucis s’évaporer.</p>
                  <h2>Conservation</h2>
                  <p>Conservez cette bombe de bain dans un endroit frais et sec jusqu'au moment où vous vous sentirez d'attaque pour la libérer et en profiter.</p>

                </div>
                <div className="product-image">
                  
                    <img alt={stripePrice.product.name} src={stripePrice.product.images} />
                    
                    <div className="product-price-basket">
                      <p>
                        {' '}
                        {formatCurrencyString({
                          value: parseInt(stripePrice.unit_amount),
                          currency: stripePrice.currency,
                        })}
                      </p>
                     
                      <AddBasket sku={stripePrice}></AddBasket>
                    </div>

                    <h2>Fait main avec des produits bio</h2>
                  
                  <p>{stripePrice.product.metadata.ingredients}</p>

                </div>

              </div>
            </div>

            

            <div>
              <br />
              <br />
              <br />
              <br />
              <h2>Nos produits</h2>
              <Skus></Skus>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
  
}

export const pageQuery = graphql`
    query($id: String) {
        stripePrice(id: {eq: $id}) {
            unit_amount
            active
            currency
            id
            product {
                id
                images
                name
                description
                metadata {
                  ingredients
                }
            }
        }
    }
`