import React, { useState } from 'react'
import { useShoppingCart } from 'use-shopping-cart'

import '../ui/product-add-button.css'

const AddBasket = ({sku}) => {
    const { addItem } = useShoppingCart()
    const [hasAdded, setHasAdded] = useState(false)
    const [nbAdd, setNbAdd] = useState(0)

    const newSku = {
        sku: sku.id,
        name: sku.product.name,
        price: sku.unit_amount,
        currency: sku.currency,
        image: sku.product.images,
        path: sku.product.metadata.path
    }

    function handleAddItem() {
        addItem(newSku)
        setHasAdded(true)
        setNbAdd(nbAdd + 1)
  
      }

    return (
        <div className='product-add-button'>
            <button className='accent-button'  onClick={() => handleAddItem()}>
                Ajouter au panier
            </button>
            {hasAdded ? <p className='sku-card-product-added '> Le produit a été ajouté au panier : {nbAdd}x </p> : null }
        </div>
       
    )
}

export default AddBasket